<template>
  <div class="verify-user">
    <h2>Verificar OTP</h2>
    <input v-model="username" type="text" placeholder="Nombre de Usuario" style="width: 80%;" />
    <input v-model="otp" type="text" placeholder="OTP" @input="clearMessage" style="width: 80%;" />
    <button @click="verifyOTP">Verificar OTP</button>
    <p v-if="message" :class="['result', { 'valid': isValid, 'invalid': !isValid }]">
      {{ message }}
    </p>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      otp: '',
      message: '',
      isValid: null,
      geolocation: null,
      map: null,
    };
  },
  methods: {
    async verifyOTP() {
      try {
        await this.getGeolocation();
        if (this.geolocation === 'No disponible' || this.geolocation === 'No soportado') {
          this.message = 'No se pudo obtener la ubicación. Inténtalo de nuevo.';
          this.isValid = false;
          return;
        }

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/verify-otp`, {
          username: this.username,
          otp: this.otp,
          geolocation: this.geolocation,
        });

        this.isValid = response.data.isValid;
        this.message = this.isValid ? 'OTP es válido' : 'OTP no es válido';
      } catch (error) {
        console.error('Error verificando OTP', error);
        this.message = 'Error al verificar OTP';
        this.isValid = false;
      }
    },
    clearMessage() {
      this.message = '';
      this.isValid = null;
    },
    async getGeolocation() {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const lat = position.coords.latitude;
              const lon = position.coords.longitude;
              this.geolocation = `${lat}, ${lon}`;
              resolve();
            },
            (error) => {
              console.error('Error obteniendo geolocalización', error);
              this.geolocation = 'No disponible';
              resolve();
            }
          );
        } else {
          this.geolocation = 'No soportado';
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.verify-user {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.result {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.valid {
  background-color: #e6f7ff;
  color: #0066cc;
}

.invalid {
  background-color: #ffe6e6;
  color: #cc0000;
}
</style>
