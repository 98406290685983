<template>
  <div id="app">
    <h1>Authenticator App</h1>
    <div class="container">
      <CreateUser />
      <VerifyUser />
    </div>
  </div>
</template>

<script>
import CreateUser from './components/CreateUser.vue';
import VerifyUser from './components/VerifyUser.vue';

export default {
  name: 'App',
  components: {
    CreateUser,
    VerifyUser
  }
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  color: #333;
  text-align: center;
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Esto añade espacio entre los componentes */
}

.container > div {
  width: calc(50% - 10px); /* Ajusta el ancho para mantener el espacio */
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

input, button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}
</style>