<template>
  <div class="create-user">
    <h2>Crear Usuario</h2>
    <input v-model="username" type="text" placeholder="Nombre de Usuario" style="width: 80%;" />
    <button @click="createUser">Crear Usuario</button>
    <div v-if="qrCode" class="qr-code">
      <h3>Escanea este código QR con tu app de autenticación</h3>
      <img :src="qrCode" alt="QR Code" />
    </div>
    <p v-if="message" class="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      qrCode: '',
      message: ''
    };
  },
  methods: {
    async createUser() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/auth/create-user`, { username: this.username });
        this.qrCode = response.data.qrCode;
        this.message = `Usuario creado con secreto: ${response.data.secret}`;
      } catch (error) {
        console.error('Error creando usuario', error);
        this.message = 'Error al crear usuario';
      }
    }
  }
};
</script>

<style scoped>
.create-user {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.qr-code {
  text-align: center;
  margin-top: 20px;
}

.qr-code img {
  max-width: 200px;
}

.message {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}
</style>
